import { Button } from "@atoms/button/button";
import { Page } from "@atoms/layout/page";
import { useSetRecoilState } from "recoil";
import { SendOrderModal } from "../send-order-modal";

export const SupplierSendOrderPage = () => {
  const setSendOrderModal = useSetRecoilState(SendOrderModal);
  return (
    <Page>
      <>
        <Button onClick={() => setSendOrderModal({ open: true })}>
          Envoi commande
        </Button>
      </>
    </Page>
  );
};
